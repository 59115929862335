import styled from "styled-components";

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 0.625rem;
  padding-top: 1.25rem;
`;

export const Input = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  color: var(--genseq-blue);
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  &::placeholder {
    color: #e5e5e5;
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
  }
  &:focus {
    outline: none;
    border-color: var(--genseq-orange);
  }
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

export const FormCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p {
    font-weight: 300;
    font-size: 0.75rem;
    a {
      color: var(--genseq-blue);
    }
  }
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

export const FormCheckbox = styled.input`
  appearance: none;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  cursor: pointer;
  height: 1.5rem;
  margin-top: 0.125rem;
  outline: none;
  padding: 0.625rem;
  position: relative;
  transition: 0.5s;
  width: 1.5rem;

  &:checked:before {
    color: var(--genseq-orange);
    content: "✓";
    font-size: 1rem;
    height: 1rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-40%, -60%);
    width: 1rem;
  }
  &:active {
    border: 0.125rem solid #34495e;
  }
  &:focus {
    border-color: var(--genseq-orange);
  }
`;

export const Back = styled.button`
  align-items: center;
  align-self: flex-start !important;
  background: var(--genseq-blue);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  gap: 10px;
  text-decoration: none;
  margin-top: 0 !important;
  margin-bottom: 1rem;
  padding: 0.5rem;
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: #ffffff;
    }
  }
`;

export const Message = styled.span`
  display: block;
  text-align: right;
  width: 100%;
`;
