import React from "react";
import styled from "styled-components";
import { useWindowSize } from "../../../hooks";
import { Markup } from "interweave";

const RequirementsTable = ({ types }) => {
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      {windowWidth >= 720 ? (
        <>
          <h5>Sample Type</h5>
          <h5>Quantity</h5>
          <h5>Quality</h5>
          <h5>Collection Buffer</h5>
          <h5>Shipping Conditions</h5>
          {types.map((type) => (
            <>
              <p>
                <Markup content={type.sampleType} />
              </p>
              <p>
                <Markup content={type.quantity} />
              </p>
              <p>
                <Markup content={type.quality} />
              </p>
              <p>
                <Markup content={type.collectionBuffer} />
              </p>
              <p>
                <Markup content={type.shippingConditions} />
              </p>
            </>
          ))}
        </>
      ) : (
        <>
          {types.map((type) => (
            <>
              <h5>Sample Type</h5>
              <p>
                <Markup content={type.sampleType} />
              </p>
              <h5>Quantity</h5>
              <p>
                <Markup content={type.quantity} />
              </p>
              <h5>Quality</h5>
              <p>
                <Markup content={type.quality} />
              </p>
              <h5>Collection Buffer</h5>
              <p>
                <Markup content={type.collectionBuffer} />
              </p>
              <h5>Shipping Conditions</h5>
              <p>
                <Markup content={type.shippingConditions} />
              </p>
            </>
          ))}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  h5 {
    font-size: 1rem;
    padding: 1rem 1.25rem;
    &:nth-of-type(5n) {
      border-bottom: 1px solid #e5e5e5;
    }
    &:last-of-type {
        border-bottom: none;
      }
  }
  p {
    padding: 1rem 1.25rem;
      &:nth-child(5n) {
        border-bottom: 1px solid #e5e5e5;
      }
      &:last-of-type {
        border-bottom: none;
      }
  }
  @media screen and (min-width: 720px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    h5 {
      &:nth-of-type(5n) {
        border-bottom: none;
      }
      &:not(:last-of-type) {
        border-right: 1px solid #e5e5e5;
      }
    }
    p {
      &:not(:nth-last-child(-n + 5)) {
        border-bottom: 1px solid #e5e5e5;
      }
      &:not(:nth-child(5n)) {
        border-right: 1px solid #e5e5e5;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    margin-left: -1.25rem;
  }
`;

export default RequirementsTable;
