import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import DropdownNavigation from "./DropdownNavigation";
import ServicesMenu from "./ServicesMenu";
import { Button, Dialog, ContactUsForm } from "../../components";
import { LocalContext } from "../../context";
import { GenseqLogo, MenuIcon } from "../../assets/svgs";
import { scrollToAnchor } from "../../utils";
import { useWindowSize } from "../../hooks";

const NavigationBar = ({ scrollYProgress, pathname }) => {
  const { windowWidth } = useWindowSize();
  const [scrollY, setScrollY] = useState(0);
  const [showServicesMenu, setShowServicesMenu] = useState(false);
  const contactFormDialogRef = useRef(null);
  const {
    showContactFormDialog,
    setShowContactFormDialog,
    showRequestFormDialog,
    setShowRequestAccountFormDialog,
    setShowRequestFormDialog,
    dropdownOpen,
    setDropdownOpen,
  } = useContext(LocalContext);

  const closeContactFormDialog = () => {
    contactFormDialogRef.current.setAttribute("data-state", "close");
    contactFormDialogRef.current.children[0].setAttribute(
      "data-state",
      "close"
    );
    setShowContactFormDialog(false);
  };

  useEffect(() => {
    scrollYProgress.onChange((number) => setScrollY(number));
  }, [scrollYProgress, scrollY]);

  const fadeOut = {
    hidden: { opacity: 0, scale: 1, left: 0, translateX: "0%" },
    visible: {
      opacity: 1,
      scale: 1,
      left: 0,
      translateX: "0%",
      transition: {
        duration: 0.5,
        scale: {
          duration: 0.1,
        },
      },
    },
    scale: {
      opacity: 1,
      scale: 0.72,
      left: "50%",
      translateX: "-50%",
      transition: {
        duration: 0.3,
        scale: {
          duration: 0.3,
          delay: 0.1,
        },
      },
    },
  };

  const colorChange = {
    dark: {
      background: "rgba(6, 28, 87, 0.9)",
    },
    darker: {
      background: "rgba(6, 28, 87, 1)",
    },
    light: {
      background: "rgba(6, 28, 87, 0)",
      transition: {
        duration: 0.5,
      },
    },
  };

  const slideIn = {
    hidden: { x: "100vw", transition: { duration: 0.75 } },
    visible: { x: "0", transition: { duration: 0.75 } },
  };
  console.log(pathname);

  return (
    <AnimatePresence initial={false} mode="wait">
      <Container
        key={pathname}
        dropdownOpen={dropdownOpen}
        variants={colorChange}
        initial="light"
        animate={
          pathname.includes("cookie-policy")
            ? "dark"
            : windowWidth >= 1024 && Math.round(scrollY * 100) / 100 > 0.1
            ? "dark"
            : dropdownOpen
            ? "darker"
            : "light"
        }
      >
        {windowWidth < 1024 ? (
          <>
            <LogoContainer
              variants={fadeOut}
              initial="hidden"
              type="button"
              aria-label="Home"
              onClick={() => navigate("/")}
              animate={
                dropdownOpen
                  ? "scale"
                  : Math.round(scrollY * 100) / 100 < 0.1
                  ? "visible"
                  : "hidden"
              }
            >
              <GenseqLogo />
            </LogoContainer>
            <MenuToggle
              dropdownOpen={dropdownOpen}
              dark={Math.round(scrollY * 100) / 100 > 0.1}
              onClick={() => setDropdownOpen((previousState) => !previousState)}
            >
              <MenuIcon scrollY={scrollY} dropdownOpen={dropdownOpen} />
            </MenuToggle>
            <DropdownNavigation dropdownOpen={dropdownOpen} pathname={pathname} />
          </>
        ) : (
          <>
            <LogoContainer
              variants={fadeOut}
              initial="hidden"
              type="button"
              aria-label="Home"
              onClick={() => navigate("/")}
              animate={
                dropdownOpen
                  ? "scale"
                  : pathname !== "/"
                  ? "visible"
                  : pathname === "/" && Math.round(scrollY * 100) / 100 > 0.1
                  ? "visible"
                  : "hidden"
              }
            >
              <GenseqLogo />
            </LogoContainer>
            <Links onMouseLeave={() => setShowServicesMenu(false)}>
              {pathname !== "/" && (
                <Anchor
                  type="button"
                  id="home-navigation"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                  onMouseEnter={() => setShowServicesMenu(false)}
                  color="#FFFFFF"
                >
                  Home
                </Anchor>
              )}
              <Anchor
                type="button"
                id="about-us-navigation"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/about-us");
                }}
                onMouseEnter={() => setShowServicesMenu(false)}
                color={
                  pathname.includes("about-us")
                    ? "var(--genseq-green)"
                    : "#FFFFFF"
                }
              >
                About Us
              </Anchor>
              {pathname === "/" && (
                <Anchor
                  type="button"
                  color="#FFFFFF"
                  id="technology-navigation"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToAnchor("laboratory-and-technology", {
                      duration: 1200,
                      offset: -120,
                    });
                  }}
                  onMouseEnter={() => setShowServicesMenu(false)}
                >
                  Technology
                </Anchor>
              )}
              <Anchor
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/services");
                }}
                id="services-navigation"
                onMouseEnter={() => setShowServicesMenu(true)}
                color={
                  pathname.includes("services") || showServicesMenu
                    ? "var(--genseq-green)"
                    : "#FFFFFF"
                }
              >
                Services
              </Anchor>
              <ActionButtons>
                <Button
                  type="button"
                  solid="var(--genseq-purple)"
                  text="Contact Us"
                  id="contact-us-navigation"
                  onClick={() => setShowContactFormDialog(true)}
                  onMouseEnter={() => setShowServicesMenu(false)}
                />
                {pathname.includes("services") ? (
                  <Button
                    type="button"
                    text="Request a Quote"
                    id="request-a-quote-navigation"
                    maxWidth="220px"
                    onClick={() => setShowRequestFormDialog(true)}
                    onMouseEnter={() => setShowServicesMenu(false)}
                  />
                ) : (
                  <Button
                    type="button"
                    text="Clinical Registration"
                    id="clinical-registration-navigation"
                    maxWidth="280px"
                    onClick={() => setShowRequestAccountFormDialog(true)}
                    onMouseEnter={() => setShowServicesMenu(false)}
                  />
                )}
              </ActionButtons>
              <AnimatePresence>
                <MenuContainer
                  variants={slideIn}
                  initial="hidden"
                  exit="hidden"
                  animate={showServicesMenu ? "visible" : "hidden"}
                >
                  <ServicesMenu />
                </MenuContainer>
              </AnimatePresence>
            </Links>
          </>
        )}
        <Dialog
          large
          ref={contactFormDialogRef}
          onDismiss={closeContactFormDialog}
          isVisible={showContactFormDialog}
        >
          <ContactUsForm closeContactFormDialog={closeContactFormDialog} />
        </Dialog>
      </Container>
    </AnimatePresence>
  );
};

const Container = styled(motion.nav)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: ${({ dropdownOpen }) => (dropdownOpen ? "4rem" : "6rem")};
  position: fixed;
  gap: 3rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: all 0.3s ease-out;
  @media screen and (min-width: 1024px) {
    height: 5rem;
    background: transparent;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const MenuToggle = styled(motion.button)`
  align-items: center;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: ${({ dropdownOpen }) =>
    dropdownOpen ? "1.5rem 1.25rem" : "2rem 1.25rem"};
  transition: all 0.3s ease-out;
  &:before {
    border-radius: 3rem;
    background: ${({ dark }) =>
      dark ? "rgba(6, 28, 87, 0.9)" : "rgba(6, 28, 87, 0)"};
    content: "";
    height: 3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    z-index: 0;
  }
  svg {
    width: 1.125rem;
    height: auto;
    z-index: 8;
  }
`;

const Links = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  grid-column: 5/12;
  gap: 3rem;
  position: relative;
  @media screen and (min-width: 1024px) {
    grid-column: 4/12;
  }
  @media screen and (min-width: 1400px) {
    grid-column: 5/12;
  }
`;

const Anchor = styled(motion.button)`
  color: ${({ color }) => color} !important;
  cursor: pointer;
`;

const LogoContainer = styled(motion.button)`
  align-items: start;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  grid-column: 1/7;
  padding-left: 1.25rem;
  position: relative;
  height: 100%;
  width: max-content;
  svg {
    width: 180px;
    height: auto;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 2/4;
    padding-left: 0;
  }
  @media screen and (min-width: 1300px) {
    grid-column: 2/4;
  }
`;

const MenuContainer = styled(motion.div)`
  background-color: white;
  border-radius: 0.625rem;
  left: 0;
  padding: 0 2.625rem;
  position: absolute;
  top: 5rem;
  width: 780px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export default NavigationBar;
