import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { motion } from "framer-motion";
import { Markup } from "interweave";
import { Link } from "gatsby";
import { scrollToAnchor } from "../../../utils";
import {
  Biobanking,
  Biopharma1,
  Biopharma2,
  Biopharma3,
  Biopharma4,
  Biopharma5,
  Biopharma6,
  Biopharma7,
  Biopharma8,
  Biopharma9,
  Biopharma10,
} from "../../../assets/animated-svgs";

const ServicesMenu = ({ invert, setDropdownOpen }) => {
  const { services } = useStaticQuery(
    graphql`
      query {
        services: markdownRemark(
          frontmatter: { title: { eq: "Our Services" } }
        ) {
          frontmatter {
            serviceMenu {
              id
              title
              link
              svg
              color
            }
          }
        }
      }
    `
  );
  const SVGMapper = {
    biobanking: Biobanking,
    biopharma1: Biopharma1,
    biopharma2: Biopharma2,
    biopharma3: Biopharma3,
    biopharma4: Biopharma4,
    biopharma5: Biopharma5,
    biopharma6: Biopharma6,
    biopharma7: Biopharma7,
    biopharma8: Biopharma8,
    biopharma9: Biopharma9,
    biopharma10: Biopharma10,
  };
  const { serviceMenu } = services.frontmatter;
  return (
    <Container>
      {serviceMenu.map((service) => {
        const SVG = SVGMapper[service.svg];
        return (
          <MenuItem
            key={service.title}
            to={service.link}
            invert={invert}
            onClick={() => {
              if (invert) {
                setDropdownOpen(false);
              }
              if (service.svg === "biobanking") {
                setTimeout(() => {
                  scrollToAnchor("biobanking-services", {
                    duration: 1200,
                    offset: -120,
                  });
                }, 1750);
              }
            }}
          >
            <SVGContainer
              position={service.id}
              whileHover={{
                scale: 1.1,
                transition: { ease: "easeInOut", duration: 0.375 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              <SVG color={service.color} invert={invert} />
            </SVGContainer>
            <h5>
              <Markup content={service.title} />
            </h5>
            <meta content={service.id} />
          </MenuItem>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  align-items: start;
  display: grid;
  gap: 2rem 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  padding: 2.625rem 0;
  @media screen and (min-width: 1024px) {
    gap: 2rem 0.5rem;
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const SVGContainer = styled(motion.div)`
  height: auto;
  margin: 0 auto;
  width: ${({ position }) =>
    position === 4
      ? "32px"
      : position === 7
      ? "32px"
      : position === 3
      ? "52px"
      : position === 5
      ? "56px"
      : position === 9
      ? "38px"
      : "48px"};
  @media screen and (min-width: 1024px) {
    margin: unset;
  }
`;

const MenuItem = styled(Link)`
  display: grid;
  cursor: pointer;
  grid-template-rows: 50px 1fr;
  font-size: 1.25rem;
  gap: 0.875rem;
  justify-content: center;
  color: ${({ invert }) => (invert ? "#FFFFFF" : "var(--genseq-blue)")};
  text-align: center;
  p {
    color: ${({ color }) => color};
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    transition: all 0.3s ease-out;
  }
  @media screen and (min-width: 1024px) {
    justify-content: start;
    text-align: left;
  }
`;

export default ServicesMenu;
