const serviceBreadcrumb = ({ title, slug }) => {
  let data = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Services",
        item: "https://genseqgroup.com/services/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: `${title}`,
        item: `https://genseqgroup.com/services/${slug}/`,
      },
    ],
  };

  return JSON.stringify(data);
};

export default serviceBreadcrumb;
