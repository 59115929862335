import React, { useEffect, useState, createRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import BasicInformation from "./BasicInformation";
import ShippingInformation from "./ShippingInformation";
import BillingInformation from "./BillingInformation";
import ReviewInformation from "./ReviewInformation";
import { Button } from "../../../../components";

const resetForm = () => ({
  name: "",
  institution: "",
  department: "",
  email: "",
  phoneNumber: "",
  medicalRegistrationNumberCoruNumber: "",
  sameAsShipping: true,
  financeContact: "",
  financeContactEmail: "",
  financeContactPhoneNumber: "",
  requirePoForPayments: false,
  taxableStatus: "",
  vatTaxIdNumber: "",
  confirmHCP: false,
  acceptTermsAndConditions: false,
});

const resetAddressDetails = () => ({
  address1: "",
  address2: "",
  address3: "",
  county: "",
  postcode: "",
  country: "",
});

const RequestAccountForm = ({ closeRequestAccountFormDialog }) => {
  const [message, setMessage] = useState("");
  const [showBillingAddressFields, setShowBillingAddressFields] =
    useState(false);
  const [botField, setBotfield] = useState("");
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [formPage, setFormPage] = useState(1);

  const [formValues, setFormValues] = useState(resetForm());

  const [shippingAddress, setShippingAddress] = useState(resetAddressDetails());

  const [billingAddress, setBillingAddress] = useState(resetAddressDetails());

  useEffect(() => {
    if (formValues.sameAsShipping) {
      setBillingAddress(shippingAddress);
    } else {
      setBillingAddress(resetAddressDetails());
    }
  }, [formValues.sameAsShipping, shippingAddress]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    if (message) {
      setMessage("");
    }
    if (!isMessageSent) {
      setFormValues((currentValues) => ({
        ...currentValues,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  useEffect(() => {
    setShowBillingAddressFields(!formValues.sameAsShipping);
  }, [formValues.sameAsShipping]);

  const recaptchaRef = createRef();

  const encodeData = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    return formData;
  };

  const sendFormToNetlify = async (e) => {
    setIsMessageSending(true);

    const form = e.target;

    const options = {
      method: "POST",
      body: encodeData({
        "form-name": form.getAttribute("name"),
        name: formValues.name,
        institution: formValues.institution,
        department: formValues.department,
        medicalRegistrationNumberCoruNumber: formValues.medicalRegistrationNumberCoruNumber,
        email: formValues.email,
        phoneNumber: formValues.phoneNumber,
        shippingAddress: Object.values(shippingAddress)
          .filter((field) => field !== "")
          .join(", "),
        billingAddress: Object.values(billingAddress)
          .filter((field) => field !== "")
          .join(", "),
        financeContact: formValues.financeContact,
        financeContactEmail: formValues.financeContactEmail,
        financeContactPhoneNumber: formValues.financeContactPhoneNumber,
        requirePoForPayments: formValues.requirePoForPayments ? "Yes" : "No",
        taxableStatus: formValues.taxableStatus,
        vatTaxIdNumber: formValues.vatTaxIdNumber,
      }),
    };

    return fetch("/", options)
      .then((response) => response)
      .catch((error) => console.error(error));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      document.getElementById("rc-anchor-container").focus();
      return;
    }

    sendFormToNetlify(e).then(() =>
      setTimeout(() => {
        setFormValues(resetForm());
        setShippingAddress(resetAddressDetails());
        setBillingAddress(resetAddressDetails());
        setIsMessageSending(false);
        setIsMessageSent(true);
      }, 1750)
    );
  };

  useEffect(() => {
    if (isMessageSent) {
      document.getElementById("request-account-form-success-message").focus();
    }
  }, [isMessageSent]);

  return (
    <Form
      name="request-account"
      method="POST"
      id="request-account"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <SectionTitle>Request An Account</SectionTitle>
      {formPage === 1 && (
        <SectionDescription>
          <p>
            Genseq's clinical portal is intended to be used by
            <br />
            <strong>healthcare professionals only</strong>.
            <br />
            <br />
            It enables healthcare professionals to order genetic tests for their
            patients, track progress and receive reports.
            <br />
            <small style={{ paddingTop: "0.125rem", fontSize: "0.75rem" }}>
              There is no cost associated with having an account on this portal.
            </small>
          </p>
        </SectionDescription>
      )}
      <Input type="hidden" name="form-name" value="Level 1 Submission" />
      <p hidden>
        <Label>
          Don’t fill this out:
          <Input
            name="bot-field"
            value={botField}
            onChange={(e) => {
              setBotfield(e.target.value);
            }}
          />
        </Label>
      </p>
      {formPage === 1 ? (
        <BasicInformation
          formValues={formValues}
          handleInputChange={handleInputChange}
          setFormPage={setFormPage}
        />
      ) : formPage === 2 ? (
        <ShippingInformation
          formValues={formValues}
          handleInputChange={handleInputChange}
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          setFormPage={setFormPage}
        />
      ) : formPage === 3 ? (
        <BillingInformation
          formValues={formValues}
          setFormValues={setFormValues}
          handleInputChange={handleInputChange}
          showFields={showBillingAddressFields}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          setFormPage={setFormPage}
        />
      ) : (
        <>
          {isMessageSent ? (
            <Response
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              id="request-account-form-success-message"
              className="request-account-form-success-message"
            >
              Thank you for requesting an account.
              <br /><br />
              A member of our team will be in touch within<br />
              1 business day.
            </Response>
          ) : (
            <>
              <ReviewInformation
                formValues={formValues}
                handleInputChange={handleInputChange}
                shippingAddress={shippingAddress}
                billingAddress={billingAddress}
                setFormPage={setFormPage}
                recaptchaRef={recaptchaRef}
                setDisabled={setDisabled}
              />
              <Button
                type="submit"
                solid="var(--genseq-blue)"
                maxWidth="220px"
                id="request-account-form-submit"
                text={isMessageSending ? "Sending Request" : "Request Account"}
                disabled={disabled}
              />
            </>
          )}
        </>
      )}
    </Form>
  );
};

const Form = styled.form`
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  max-width: 480px;
  margin: 0 auto;
  padding: 1.875rem 1.25rem;
  span {
    color: var(--genseq-orange);
  }
  button {
    align-self: flex-end;
    margin-top: 0.875rem;
  }
  @media screen and (min-width: 780px) {
    padding: 1.875rem;
    min-width: 480px;
  }
`;

const SectionTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: left;
  width: 100%;
`;

const SectionDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.625rem;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 0.625rem;
  padding-top: 1.25rem;
`;

const Input = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  color: var(--genseq-blue);
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  &::placeholder {
    color: #e5e5e5;
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
  }
  &:focus {
    outline: none;
    border-color: var(--genseq-orange);
  }
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

const Response = styled(motion.p)`
  font-size: 1.25rem;
  color: var(--genseq-orange);
  padding: 1rem 0;
`;

export default RequestAccountForm;
