import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Button = ({
  solid,
  text,
  id,
  type,
  onClick,
  onMouseEnter,
  disabled,
  maxWidth,
  transitionDelay = 1.5,
}) => {
  return (
    <Container
      solid={solid}
      type={type}
      id={id}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.5, delay: transitionDelay },
      }}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.025 }}
      onMouseEnter={onMouseEnter}
      maxWidth={maxWidth}
      disabled={disabled}
    >
      {text}
      {!solid && <GradientBorder id={id}/>}
    </Container>
  );
};

const Container = styled(motion.button)`
  background: ${({ solid, disabled }) =>
    disabled ? "#E5E5E5" : solid ? solid : "transparent"};
  border-radius: 3.125rem;
  color: white;
  font-size: 1rem;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "185px")};
  padding: 1rem 2rem;
  text-transform: uppercase;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const GradientBorder = styled.div`
  animation: gradient 3s ease infinite;
  background: linear-gradient(
      to right,
      rgba(255, 125, 80, 1),
      rgba(100, 95, 230, 1),
      rgba(26, 226, 153, 1)
    )
    border-box;
  background-size: 150% 150%;
  border-radius: 3.125rem;
  border: 1px solid transparent;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
  width: 100%;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-animation: gradient 3s ease infinite;
  -moz-animation: gradient 3s ease infinite;

  @-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export default Button;
