import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion";
import ReactPlayer from "react-player/lazy";
import { PlayButton } from '../../../assets/svgs';


const MainVideo = ({ main }) => {
    const [playing, setPlaying] = useState(false);
    return (
        <WideWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }} main={main}>
          <Video>
            <StyledReactPlayer
              height="100%"
              volume={1}
              playing={playing}
              playsinline
              url="https://player.vimeo.com/video/901225642?h=fa4ac10cd4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
            />
            <Overlay
              onClick={() => (!playing ? setPlaying(true) : setPlaying(false))}
              whileHover={{
                scale: 1.075,
                transition: { duration: 0.3 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              {!playing && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                >
                  <PlayButton />
                </motion.div>
              )}
            </Overlay>
          </Video>
        </WideWrapper>
    )
};

const WideWrapper = styled(motion.div)`
  border-radius: 0.625rem;
  grid-column: ${({ main }) => main ? "1/7" : "none"};
  margin:  ${({ main }) => main ? "-1rem 1.25rem 3rem 1.25rem" : "-1rem 0 3rem 0"};
  overflow: hidden;
  @media screen and (min-width: 1024px) {
    grid-column: ${({ main }) => main ? "2/12" : "none"};
    margin: -1rem 0 3rem -4rem;
    width: calc(100% + 8rem);
  }
  @media screen and (min-width: 1600px) {
    grid-column: ${({ main }) => main ? "3/11" : "none"};
  }
`;

const Video = styled(motion.div)`
  border-radius: 0.625rem;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
  overflow: hidden;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Overlay = styled(motion.div)`
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  div {
    position: relative;
    height: 100%;
    width: 100%;
    svg {
      height: 62px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 62px;
    }
  }
  @media screen and (min-width: 1024px) {
    div {
      svg {
        height: 128px;
        width: 128px;
      }
    }
  }
`;

export default MainVideo;