import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { ListDropdownIcon } from "../../../assets/animated-svgs";

const ServiceAccordion = ({ reasons }) => {
  const [itemOpen, setItemOpen] = useState(null);
  return (
    <Container>
      {reasons.map((reason, index) => (
        <Item
          key={index}
          type="button"
          onClick={() => {
            if (itemOpen === index) {
              setItemOpen(null);
            } else {
              setItemOpen(index);
            }
          }}
          open={itemOpen === index}
        >
          <Text>
            <h5>{reason.title}</h5>
            <AnimatePresence initial={false}>
              {itemOpen === index && (
                <Description
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98], opacity: { duration: 0.2 } }}
                >
                  <p>{reason.description}</p>
                </Description>
              )}
            </AnimatePresence>
          </Text>
          <ListDropdownIcon open={itemOpen === index} height="22" width="22" />
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    margin-left: -1.25rem;
  }
`;

const Item = styled(motion.button)`
  align-items: center;
  color: var(--genseq-blue);
  cursor: pointer;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 22px;
  padding: 1.625rem 1.25rem;
  width: 100%;
  h5 {
    font-size: 1rem;
    padding-bottom: ${({ open }) => open ? '0.25rem' : '0'};
    transition: all 0.2s ease-out;
  }
  svg {
    height: 22px;
    width: 22px;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #e5e5e5;
  }
  @media screen and (min-width: 1024px) {
    padding: 1.625rem 3rem 1.625rem 1.25rem;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Description = styled(motion.div)`
  color: var(--genseq-blue);
  font-weight: 300;
`;

export default ServiceAccordion;
