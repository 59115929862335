import React, { useContext } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { motion } from "framer-motion";
import { Button } from "../../../components";
import { LinkedinIcon } from "../../../assets/svgs";
import { LocalContext } from "../../../context";
import ServicesMenu from "../ServicesMenu";

const DropdownNavigation = ({ dropdownOpen, pathname }) => {
  const dropdownOpenClose = {
    hidden: { y: "100%", transition: { duration: 0.5 } },
    visible: { y: "0", transition: { duration: 0.5 } },
  };
  const {
    setShowCookieBar,
    setShowContactFormDialog,
    setShowRequestFormDialog,
    setShowRequestAccountFormDialog,
    setDropdownOpen,
  } = useContext(LocalContext);
  return (
    <Container
      variants={dropdownOpenClose}
      initial="hidden"
      exit="hidden"
      animate={dropdownOpen ? "visible" : "hidden"}
    >
      <Links>
        <Anchor
          type="button"
          id="home-navigation"
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(false);
            navigate("/");
          }}
        >
          Home
        </Anchor>
        <Anchor
          type="button"
          id="about-us-navigation"
          onClick={(e) => {
            e.preventDefault();
            navigate("/about-us");
            setDropdownOpen(false);
          }}
        >
          About Us
        </Anchor>
        <Anchor
          type="button"
          id="services-navigation"
          onClick={(e) => {
            e.preventDefault();
            navigate("/services");
            setDropdownOpen(false);
          }}
        >
          Services
        </Anchor>
        <ServicesMenu invert setDropdownOpen={setDropdownOpen} />
      </Links>
      <Footer>
        <Button
          type="button"
          solid="var(--genseq-purple)"
          id="contact-us-navigation"
          text="Contact Us"
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(false);
            setShowContactFormDialog(true);
          }}
        />
        {pathname.includes("services") ? (
          <Button
            type="button"
            text="Request a Quote"
            id="request-a-quote-navigation"
            maxWidth="220px"
            onClick={(e) => {
              e.preventDefault();
              setDropdownOpen(false);
              setShowRequestFormDialog(true);
            }}
          />
        ) : (
          <Button
            type="button"
            text="Clinical Registration"
            id="clinical-registration-navigation"
            maxWidth="280px"
            onClick={(e) => {
              e.preventDefault();
              setDropdownOpen(false);
              setShowRequestAccountFormDialog(true);
            }}
          />
        )}
        <Social>
          <a
            href="https://www.linkedin.com/company/genseq"
            target="_blank"
            aria-label="Company LinkedIn Profile"
          >
            <LinkedinIcon />
          </a>
        </Social>
        <Utility>
          <p>©2023 Genseq All Rights Reserved.</p>
          <div>
            <div
              type="button"
              onClick={() => {
                navigate("/terms-and-conditions");
                setDropdownOpen(false);
              }}
            >
              Terms & Conditions
            </div>
            <div
              type="button"
              onClick={() => {
                navigate("/privacy-policy");
                setDropdownOpen(false);
              }}
            >
              Privacy Policy
            </div>
            <div
              type="button"
              onClick={() => {
                navigate("/cookie-policy");
                setDropdownOpen(false);
              }}
            >
              Cookie Policy
            </div>
          </div>
        </Utility>
      </Footer>
    </Container>
  );
};

const Container = styled(motion.div)`
  background: var(--genseq-blue);
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  position: absolute;
  top: 4rem;
  left: 0;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 4rem);
  overflow: hidden;
  z-index: 10;
`;

const Links = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  scroll-margin: 0;
  top: 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0.5rem;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 0.625rem;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(217, 217, 217, 0.2);
    border-radius: 0.625rem;
  }
`;

const Anchor = styled.button`
  cursor: pointer;
  font-size: 2rem;
  line-height: 2.875rem;
  padding: 0.5rem 1.25rem;
  &:first-of-type {
    margin-top: 3rem;
  }
`;

const Footer = styled.div`
  background: var(--genseq-blue);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  width: 100%;
  padding: 2rem 1.25rem;
  button {
    margin: 0 auto 1.25rem auto;
  }
  &:before {
    background: #e5e5e5;
    content: "";
    height: 1px;
    left: 1.25rem;
    top: 0;
    width: calc(100% - 2.5rem);
    position: absolute;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.125rem;
  a {
    height: 24px;
    width: 24px;
    svg {
      height: 100%;
      width: 100%;
      circle {
        fill: #ffffff;
      }
      path {
        fill: var(--genseq-blue);
      }
    }
  }
`;

const Utility = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  gap: 0.25rem;
  div {
    display: flex;
    gap: 1.125rem;
    div {
      color: #ffffff;
      cursor: pointer;
      position: relative;
      height: max-content;
      &:hover {
        font-weight: 400;
        margin-top: -1px;
        &:before {
          bottom: 0;
        }
      }
      &:before {
        bottom: 1px;
        content: "";
        background: #ffffff;
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
  a {
    color: #ffffff;
    position: relative;
    height: max-content;
    &:hover {
      font-weight: 400;
      margin-top: -1px;
      &:before {
        bottom: 0;
      }
    }
    &:before {
      bottom: 1px;
      content: "";
      background: #ffffff;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`;

export default DropdownNavigation;
