import React, { useState } from "react";
import styled from "styled-components";
import { Markup } from "interweave";
import { Link } from "gatsby";
import {
  Biopharma1,
  Biopharma2,
  Biopharma3,
  Biopharma4,
  Biopharma5,
  Biopharma6,
  Biopharma7,
  Biopharma8,
  Biopharma9,
  Biopharma10,
} from "../../../../assets/animated-svgs";
import { useWindowSize } from "../../../../hooks";

const Menu = ({ menuItems }) => {
  const { windowWidth } = useWindowSize();
  const SVGMapper = {
    biopharma1: Biopharma1,
    biopharma2: Biopharma2,
    biopharma3: Biopharma3,
    biopharma4: Biopharma4,
    biopharma5: Biopharma5,
    biopharma6: Biopharma6,
    biopharma7: Biopharma7,
    biopharma8: Biopharma8,
    biopharma9: Biopharma9,
    biopharma10: Biopharma10,
  };
  const [hovered, setHovered] = useState(null);
  return (
    <Container>
      {menuItems
        .filter((item) => item.svg !== "biobanking")
        .map((item) => {
          const SVG = SVGMapper[item.svg];
          return (
            <MenuItem
              key={item.title}
              onMouseEnter={() => setHovered(item.id)}
              onMouseLeave={() => setHovered(null)}
              color={hovered === item.id ? item.color : "transparent"}
              to={item.link}
            >
              <SVGContainer position={item.id}>
                <SVG
                  color={
                    hovered === item.id || windowWidth < 1024
                      ? item.color
                      : "#061C57"
                  }
                />
              </SVGContainer>
              <h5>
                <Markup content={item.title} noHtml={windowWidth >= 1024} />
              </h5>
              {windowWidth >= 1024 && <p>See More</p>}
            </MenuItem>
          );
        })}
    </Container>
  );
};

const Container = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1 / 7;
  /* margin-top: 9rem; */
  gap: 3rem;
  padding: 0 1.25rem;
  @media screen and (min-width: 720px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column: 2 / 12;
    padding: unset;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const SVGContainer = styled.div`
  height: auto;
  width: ${({ position }) =>
    position === 4
      ? "32px"
      : position === 7
      ? "32px"
      : position === 3
      ? "52px"
      : position === 5
      ? "56px"
      : position === 9
      ? "38px"
      : "48px"};
  margin: 0 auto;
  @media screen and (min-width: 720px) {
    margin: unset;
  }
`;

const MenuItem = styled(Link)`
  align-items: start;
  color: var(--genseq-blue);
  cursor: pointer;
  display: grid;
  font-size: 1.25rem;
  grid-template-rows: 50px 1fr;
  gap: 0.875rem;
  justify-content: center;
  text-align: center;
  p {
    color: ${({ color }) => color};
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    transition: all 0.3s ease-out;
  }
  @media screen and (min-width: 720px) {
    justify-content: start;
    text-align: left;
  }
`;

export default Menu;
