import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { Markup } from "interweave";
import {
  Laboratory1,
  Laboratory2,
  Laboratory3,
  Laboratory4,
  Laboratory5,
  Team1,
  Team2,
  Team3,
} from "../../../assets/animated-svgs";

const NarrowCarousel = ({ slides, minHeight, duration }) => {
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => Math.abs(offset) * velocity;
  const [pause, setPause] = useState(false);
  const [[page, direction], setPage] = useState([0, 0]);
  const index = wrap(0, slides.length, page);
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  let timer;

  useEffect(() => {
    clearTimeout(timer);
    if(!pause) {
    timer = setTimeout(() => {
      paginate(1);
    }, duration);
  }
  }, [page, pause]);

  const indicatorWidth = 250 / slides.length;

  const SVGMapper = {
    'team1': Team1,
    'team2': Team2,
    'team3': Team3,
    'laboratory1': Laboratory1,
    'laboratory2': Laboratory2,
    'laboratory3': Laboratory3,
    'laboratory4': Laboratory4,
    'laboratory5': Laboratory5
  };

  const SVG = slides[index].svg ? SVGMapper[slides[index].svg] : SVGMapper['team1'];

  return (
    <Carousel smallerSpacing={slides[index].description}>
      <Track>
        <Slide
          key={page}
          custom={direction}
          minHeight={minHeight}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 300 },
            opacity: { duration: 1 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onMouseDown={() => {
            clearTimeout(timer);
            setPause(true);
          }}
          onMouseLeave={() => {
            clearTimeout(timer);
            setPause(false);
          }}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          {slides[index].svg && (
            <SVGContainer><SVG color={slides[index].color} /></SVGContainer>
          )}
          <h5>
            <Markup content={slides[index].title} />
          </h5>
          {slides[index].description && (
            <p>
            <Markup content={slides[index].description} />
            </p>
          )}
        </Slide>
      </Track>
      <Indicator
        width={indicatorWidth * (index + 1)}
        color={slides[index].color || "#1AE299"}
      />
    </Carousel>
  );
};

const Carousel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ smallerSpacing }) => (smallerSpacing ? "1rem" : "3rem")};
  height: 100%;
  width: 100%;
`;

const Track = styled(motion.div)`
  cursor: grab;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  position: relative;
  &:active {
    cursor: grabbing;
  }
`;

const Slide = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight};
  h5 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  p {
    text-align: center;
  }
`;

const Indicator = styled.div`
  width: 240px;
  height: 1px;
  background: #e5e5e5;
  position: relative;
  overflow: visible;
  &:before {
    content: "";
    background: ${({ color }) => color};
    width: ${({ width }) => `${width}px`};
    left: 0;
    height: 0.25rem;
    position: absolute;
    top: -1.5px;
    transition: all 0.5s ease-in-out;
  }
`;

const SVGContainer = styled.div`
  height: 100%;
  width: 100%;
  svg {
    height: 82px;
    width: 100%;
  }
  margin-bottom: 1.75rem;
`;

export default NarrowCarousel;
