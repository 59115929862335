import React from "react";
import styled from "styled-components";
import { Markup } from "interweave";

const ServiceDetails = ({
  included,
  technicalSpecifications,
  deliverables,
  turnaroundTime,
  sampleRequirements,
}) => {
  return (
    <Container>
      <h5>What is included?</h5>
      <Text>
      <Markup content={included} />
      </Text>
      <h5>Technical Specifications</h5>
      <Text>
      {technicalSpecifications.map((specification) => (
        <p>
          <strong>{specification.title}</strong>:{" "}
          <Markup content={specification.description} />
        </p>
      ))}
      </Text>
      <h5>Deliverables</h5>
      <Text>
      <Markup content={deliverables} />
      </Text>
      <h5>Turnaround Time</h5>
      <Text>
      <Markup content={turnaroundTime} />
      </Text>
      <h5>Sample Requirements</h5>
      <Text>
      <Markup content={sampleRequirements} />
      </Text>
      <SmallText>*Turnaround times are indicative and may be subject to sample type and volume.</SmallText>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  position: relative;
  margin-bottom: 2rem;
  h5 {
    font-size: 1rem;
    padding: 1rem 1.25rem;
    &:not(:last-of-type) {
    border-bottom: 1px solid #e5e5e5;
  }
  }
  @media screen and (min-width: 1024px) {
    margin-left: -1.25rem;
    margin-bottom: 0;
  }
`;

const Text = styled.div`
  border-left: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  u {
    text-decoration: none;
    position: relative; 
    &:before {
      bottom: 1px;
      content: "";
      background: var(--genseq-blue);
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  p {
    margin-bottom: 1.25rem;
  }
  a {
    color: var(--genseq-green);
    position: relative;
    &:before {
      bottom: 1px;
      content: "";
      background: var(--genseq-green);
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const SmallText = styled.span`
  position: absolute;
  left: 0;
  bottom: -2.25rem;
  font-size: 0.75rem;
  @media screen and (min-width: 1024px) {
    left: 1.25rem;
    bottom: -1.5rem;
  }
`;

export default ServiceDetails;
