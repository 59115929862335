import React, { useState, useEffect, useContext, useRef } from "react";
import { AnimatePresence, motion, useScroll } from "framer-motion";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import {
  NavigationBar,
  Footer,
  CookieBar,
  Dialog,
  RequestQuoteForm,
  RequestAccountForm,
} from "../../components";
import { LocalContext } from "../../context";
import { GlobalStyle } from "../../styles";

const Layout = ({ children }) => {
  const { scrollYProgress } = useScroll();
  const { pathname, search } = useLocation();

  const queryParams = queryString.parse(search);

  const {
    showCookieBar,
    setShowCookieBar,
    showRequestFormDialog,
    setShowRequestFormDialog,
    setShowContactFormDialog,
    showRequestAccountFormDialog,
    setShowRequestAccountFormDialog,
  } = useContext(LocalContext);

  useEffect(() => {
    if (queryParams.contact) {
      setShowContactFormDialog(true);
    }
  }, []);

  const requestFormDialogRef = useRef(null);
  const requestAccountFormDialogRef = useRef(null);

  useEffect(() => {
    if (document.cookie.indexOf("genseq-cookie-policy-set=") === -1) {
      setShowCookieBar(true);
    }
  }, []);

  const closeRequestFormDialog = () => {
    requestFormDialogRef.current.setAttribute("data-state", "close");
    requestFormDialogRef.current.children[0].setAttribute(
      "data-state",
      "close"
    );
    setShowRequestFormDialog(false);
  };

  const closeRequestAccountFormDialog = () => {
    requestAccountFormDialogRef.current.setAttribute("data-state", "close");
    requestAccountFormDialogRef.current.children[0].setAttribute(
      "data-state",
      "close"
    );
    setShowRequestAccountFormDialog(false);
  };

  return (
    <AnimatePresence mode="wait">
      <GlobalStyle />
      <Container>
        <NavigationBar scrollYProgress={scrollYProgress} pathname={pathname} />
        {children}
        <Footer pathname={pathname} />
      </Container>
      <Dialog
        large
        ref={requestFormDialogRef}
        onDismiss={closeRequestFormDialog}
        isVisible={showRequestFormDialog}
      >
        <RequestQuoteForm closeRequestFormDialog={closeRequestFormDialog} />
      </Dialog>
      <Dialog
        large
        ref={requestAccountFormDialogRef}
        onDismiss={closeRequestAccountFormDialog}
        isVisible={showRequestAccountFormDialog}
      >
        <RequestAccountForm
          closeRequestAccountFormDialog={closeRequestAccountFormDialog}
        />
      </Dialog>
      <AnimatePresence mode="wait">
        {showCookieBar && <CookieBar />}
      </AnimatePresence>
    </AnimatePresence>
  );
};

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  overflow-x: hidden;
`;

export default Layout;
