import * as React from "react";
const SvgCore2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 38"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M48.144 30.999V37H1V1h47.144v25.2"
    />
    <path
      stroke="#1AE299"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.88 32.2H5.834V5.8H43.31v26.4H19.132M33.94 5.8v26.4M24.574 5.8v26.4M15.205 5.8v26.4M43.31 19H5.834"
    />
    <ellipse cx={48.144} cy={28.599} fill="#061C57" rx={0.856} ry={0.849} />
  </svg>
);
export default SvgCore2;
