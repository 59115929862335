import React, { createContext, useState } from "react";

const LocalContext = createContext();

const LocalContextProvider = (props) => {
  const [showContactFormDialog, setShowContactFormDialog] = useState(false);
  const [showRequestFormDialog, setShowRequestFormDialog] = useState(false);
  const [showRequestAccountFormDialog, setShowRequestAccountFormDialog] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCookieBar, setShowCookieBar] = useState(false);
  return (
    <LocalContext.Provider
      value={{
        showContactFormDialog, 
        setShowContactFormDialog,
        showRequestFormDialog, setShowRequestFormDialog,
        showCookieBar, 
        setShowCookieBar,
        dropdownOpen, 
        setDropdownOpen,
        showRequestAccountFormDialog, 
        setShowRequestAccountFormDialog
      }}
    >
      {props.children}
    </LocalContext.Provider>
  );
};

export { LocalContextProvider };
export default LocalContext;
