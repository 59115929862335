import React from "react";
import styled from "styled-components";
import { useWindowSize } from "../../hooks";
import { scrollToAnchor } from "../../utils";
import { navigate } from "gatsby";
import { GenseqLogoDark, CapAccreditedLogo } from "../../assets/svgs";
import SecondaryFooter from "./SecondaryFooter";

const Footer = ({ pathname }) => {
  const { windowWidth } = useWindowSize();
  return (
    <>
      <Container>
        <InnerContainer>
          {windowWidth >= 1024 && (
            <Block>
              <GenseqLogoDark
                style={{ cursor: "pointer" }}
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (pathname !== "/") {
                    navigate("/");
                  }
                  scrollToAnchor("top", {
                    duration: 1200,
                    offset: 0,
                  });
                }}
              />
            </Block>
          )}
          <Block>
            <h5>Who We Are</h5>
            <AnchorLink
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (pathname !== "/") {
                  navigate("/");
                }
                setTimeout(() => {
                  scrollToAnchor("team-and-approach", {
                    duration: 1200,
                    offset: -96,
                  });
                }, 1000);
              }}
            >
              <p>Our Team and Our Approach</p>
            </AnchorLink>
            <br />
            <AnchorLink
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (pathname !== "/") {
                  navigate("/");
                }
                setTimeout(() => {
                  scrollToAnchor("laboratory-and-technology", {
                    duration: 1200,
                    offset: -120,
                  });
                }, 1000);
              }}
            >
              <p>Our Laboratory and Our Technology</p>
            </AnchorLink>
            <br />
            <AnchorLink
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (pathname !== "/about-us/") {
                  navigate("/about-us");
                }
                setTimeout(() => {
                  scrollToAnchor("our-credentials", {
                    duration: 1200,
                    offset: -120,
                  });
                }, 1000);
              }}
            >
              <p>Our Credentials</p>
            </AnchorLink>
          </Block>
          <Block>
            <h5>Our Services</h5>
            <AnchorLink
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (pathname !== "/") {
                  navigate("/");
                }
                setTimeout(() => {
                  scrollToAnchor("core-services", {
                    duration: 1200,
                    offset: -120,
                  });
                }, 1000);
              }}
            >
              <p>Core Services</p>
            </AnchorLink>
            <br />
            <AnchorLink
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (pathname !== "/") {
                  navigate("/");
                }
                setTimeout(() => {
                  scrollToAnchor("biopharma-/-research-services", {
                    duration: 1200,
                    offset: -120,
                  });
                }, 1000);
              }}
            >
              <p>Biopharma / Research Services</p>
            </AnchorLink>
            <br />
            <AnchorLink
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (pathname !== "/") {
                  navigate("/");
                }
                setTimeout(() => {
                  scrollToAnchor("biobanking-and-sample-management-services", {
                    duration: 1200,
                    offset: -120,
                  });
                }, 1000);
              }}
            >
              <p>Biobanking and Sample Management Services</p>
            </AnchorLink>
          </Block>
          <Block>
            <h5>Contact Us</h5>
            {/* <p>
              <a href="mailto:info@genseqgroup.com">info@genseqgroup.com</a>
            </p>
            <br /> */}
            <p>
              Ground Floor, Building 4,
              <br />
              Cherrywood Business Park,
              <br />
              Dublin, D18 K7W4, Ireland
            </p>
            {windowWidth >= 1024 && (
              <SVGContainer>
                <CapAccreditedLogo />
              </SVGContainer>
            )}
          </Block>
          {windowWidth < 1024 && (
            <SVGContainer>
              <CapAccreditedLogo />
            </SVGContainer>
          )}
        </InnerContainer>
      </Container>
      <SecondaryFooter />
    </>
  );
};

const Container = styled.footer`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  &:before {
    content: "";
    background: #e5e5e5;
    height: 1px;
    left: 0.625rem;
    position: absolute;
    top: 0;
    width: calc(100% - 1.25rem);
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    &:before {
      left: 1.25rem;
      width: calc(100% - 2.5rem);
    }
  }
`;

const InnerContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-column: 1/7;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 3rem;
  padding: 1.5rem 1.25rem;
  @media screen and (min-width: 1024px) {
    padding: 2rem 0 1rem 0;
    grid-column: 2/12;
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 0.75rem;
    a {
      color: var(--genseq-blue);
      position: relative;
      &:hover {
        font-weight: 400;
      }
      &:before {
        bottom: 0;
        content: "";
        background: var(--genseq-blue);
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
  svg {
    width: 135px;
    height: 32px;
  }
  @media screen and (min-width: 1024px) {
    h5 {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const AnchorLink = styled.button`
  color: var(--genseq-blue);
  cursor: pointer;
  display: block;
  font-weight: 300;
  text-align: left;
  &:hover {
    font-weight: 400;
    margin-top: -2px;
  }
`;

const SVGContainer = styled.div`
  height: 100%;
  width: 100%;
  svg {
    height: auto;
    width: 154px;
    margin-left: auto;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 1.5rem;
  }
`;

export default Footer;
