import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate, Link } from "gatsby";
import { Button } from "../../../components";
import { LocalContext } from "../../../context";
import { scrollToAnchor } from "../../../utils";
import { useWindowSize } from "../../../hooks";
import { ArrowBack } from "../../../assets/svgs";
import { HeaderPattern } from "../../../assets/animated-svgs";

const MediumHeader = ({
  hero,
  title,
  type,
  description,
  buttonText,
  buttonId,
  buttonLink,
}) => {
  const { setShowContactFormDialog } = useContext(LocalContext);
  const containerRef = useRef(null);
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      {windowWidth >= 1024 && (
        <>
          <Pattern type={type}>
            <HeaderPattern containerRef={containerRef} />
          </Pattern>
          {windowWidth >= 1024 && <GreenGradient />}
        </>
      )}
      <Background>
        <GatsbyImage
          image={getImage(hero)}
          alt={title}
          style={{ zIndex: 0, height: "100%" }}
        />
      </Background>
      <Content>
        {type === "service" && (
          <Return
            to="/services"
            onClick={() => {
              setTimeout(() => {
                scrollToAnchor("biopharma-services", {
                  duration: 1200,
                  offset: -48,
                });
              }, 1750);
            }}
          >
            <ArrowBack />
            <p>Biopharma/ Research Service</p>
          </Return>
        )}
        <Title
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.75 }}
        >
          {title}
        </Title>
        {description && <Description initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.75 }}>{description}</Description>}
        {buttonText && (
          <Button
            text={buttonText}
            id={buttonId}
            type="button"
            onClick={() => {
              if (buttonLink) {
                navigate(buttonLink);
              } else {
                setShowContactFormDialog(true);
              }
            }}
          />
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background: var(--genseq-blue);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Content = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  gap: 1.25rem;
  grid-column: 1 / 7;
  padding: 0 1.25rem;
  margin: 9rem 0 3rem 0;
  position: relative;
  z-index: 2;
  justify-content: flex-end;
  @media screen and (min-width: 1024px) {
    gap: 3.25rem;
    margin: 9rem 0 6rem 0;
    padding: unset;
    grid-column: 2 / 12;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;
`;

const Title = styled(motion.h1)`
  font-size: 2.875rem;
  font-weight: 400;
  max-width: 825px;
  @media screen and (min-width: 1024px) {
    font-size: 4rem;
  }
`;

const Return = styled(Link)`
  align-items: center;
  color: var(--genseq-green);
  display: flex;
  font-size: 1rem;
  gap: 10px;
  svg {
    width: 20px;
    height: 18px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.5rem;
    margin-bottom: -2rem;
  }
`;

const Description = styled(motion.p)`
  font-size: 1.25rem;
  max-width: 690px;
`;

const Pattern = styled.div`
  position: absolute;
  top: ${({ type }) => (type === "service" || type === "other" ? "120%" : "75%")};
  right: -40%;
  height: ${({ type }) => (type === "service" || type === "other" ? "200%" : "120%")};
  width: 100%;
  z-index: 2;
  transform: translate(0, -50%);
  svg {
    height: 100%;
    width: auto;
    right: 0;
  }
`;

const GreenGradient = styled.div`
  position: absolute;
  background: linear-gradient(
    280deg,
    rgba(26, 226, 153, 1) 0%,
    rgba(26, 226, 153, 0) 50%
  );
  mix-blend-mode: hard-light;
  width: 600px;
  height: 150%;
  top: -20%;
  right: 0;
  z-index: 4;

  transform-origin: center center;
  animation: moveVertical 5s ease infinite alternate;
  opacity: 1;
  z-index: 3;

  @keyframes moveVertical {
    0% {
      transform: translate(25%, -20%);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(10%, -20%);
    }
  }
`;

export default MediumHeader;
